<template>
  <div class="main">
    <div class="modal">
      <div class="modal-header">
        <h2 class="title">Регистрация</h2>
        <div class="block-close">
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            fill="#99A2AD"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#99A2AD"
            ></path>
          </svg>
        </div>
      </div>
      <div class="registration-form modal-right">
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group">
            <select
              id="country"
              v-model="country"
            >
              <option value="RU" selected>Россия</option>
              <option value="UA">Украина</option>
            </select>
          </div>
          <div class="form-group">
            <select id="currency" v-model="currency">
              <option value="RUB" selected>Руский рубль RUB</option>
              <option value="UAH">Украинская гривна UAH</option>
            </select>
          </div>

          <div class="form-row">
            <select
              name="telCode"
              id="telCode"
              v-model="telCode"
            >
              <option value="+7" selected>+7</option>
              <option value="+380">+380</option>
            </select>
            <input
              style="width: 100%"
              type="tel"
              id="phone"
              placeholder="Номер телефона"
              v-model="phone"
              required
            />
          </div>
          <div class="form-group" style="width: 100%">
            <input
              type="password"
              id="passowrd"
              placeholder="Введите пароль"
              v-model="password"
              required
            />
          </div>
          <div class="form-group promo-code">
            <input type="text" id="promo" v-model="promocode" />
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="check" checked>
              <p>
                Я подтверждаю, что я ознакомлен и полностью согласен с
                <a href="#">Условиями Соглашения об использовании сайта 1win</a>
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Зарегистрироваться</button>
        </form>
        <div class="sign-in">
          <p>
            Уже есть аккаунт? <a href="#">Войти</a>
          </p>
        </div>
      </div>
      <div class="bonus-block">
        <div class="bonus-item">
          <p><img src="https://v1.bundlecdn.com/img/emoji-slots.1c6e965b9-160.png" alt="img"> 500% на казино</p>
          <svg data-v-09a2bd8c="" width="19" height="19" viewBox="2 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon icon-ok-with-underlay sm bonus-checked-icon bonus-checked-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.95 18.185a2.224 2.224 0 00-2.9 0c-1.382 1.187-3.528.299-3.666-1.52a2.224 2.224 0 00-2.05-2.049c-1.817-.138-2.706-2.284-1.519-3.667a2.224 2.224 0 000-2.898c-1.187-1.383-.298-3.53 1.52-3.667a2.224 2.224 0 002.049-2.05C6.522.517 8.668-.372 10.05.815a2.224 2.224 0 002.898 0c1.383-1.187 3.53-.298 3.667 1.52a2.224 2.224 0 002.05 2.049c1.817.138 2.706 2.284 1.519 3.667a2.224 2.224 0 000 2.898c1.187 1.383.299 3.53-1.52 3.667a2.224 2.224 0 00-2.049 2.05c-.138 1.817-2.284 2.706-3.667 1.519z" fill="url(#ok-with-underlay_svg__paint0_linear)"></path><path d="M8 9.668L10.535 12 15 8" filter="url(#ok-with-underlay_svg__filter0_d)" stroke="#fff" stroke-width="1.69" stroke-linecap="round" stroke-linejoin="round" fill="transparent"></path><defs><linearGradient id="ok-with-underlay_svg__paint0_linear" x1="17.5" y1="4.5" x2="6" y2="16" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#089E4E"></stop><stop offset="1" stop-color="#31BC69"></stop></linearGradient><filter id="ok-with-underlay_svg__filter0_d" x=".155" y="3.155" width="22.69" height="19.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset dy="3"></feOffset><feGaussianBlur stdDeviation="3.5"></feGaussianBlur><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend></filter></defs></svg>
        </div>
        <!-- <div class="br"></div> -->
        <div class="bonus-item" style="border-top: 1px solid #edf0f7;">
          <p><img src="https://v1.bundlecdn.com/img/cashback.12a565952.svg" alt="img"> Кэшбэк до 30%</p>
          <svg data-v-09a2bd8c="" width="19" height="19" viewBox="2 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="icon icon-ok-with-underlay sm bonus-checked-icon bonus-checked-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.95 18.185a2.224 2.224 0 00-2.9 0c-1.382 1.187-3.528.299-3.666-1.52a2.224 2.224 0 00-2.05-2.049c-1.817-.138-2.706-2.284-1.519-3.667a2.224 2.224 0 000-2.898c-1.187-1.383-.298-3.53 1.52-3.667a2.224 2.224 0 002.049-2.05C6.522.517 8.668-.372 10.05.815a2.224 2.224 0 002.898 0c1.383-1.187 3.53-.298 3.667 1.52a2.224 2.224 0 002.05 2.049c1.817.138 2.706 2.284 1.519 3.667a2.224 2.224 0 000 2.898c1.187 1.383.299 3.53-1.52 3.667a2.224 2.224 0 00-2.049 2.05c-.138 1.817-2.284 2.706-3.667 1.519z" fill="url(#ok-with-underlay_svg__paint0_linear)"></path><path d="M8 9.668L10.535 12 15 8" filter="url(#ok-with-underlay_svg__filter0_d)" stroke="#fff" stroke-width="1.69" stroke-linecap="round" stroke-linejoin="round" fill="transparent"></path><defs><linearGradient id="ok-with-underlay_svg__paint0_linear" x1="17.5" y1="4.5" x2="6" y2="16" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#089E4E"></stop><stop offset="1" stop-color="#31BC69"></stop></linearGradient><filter id="ok-with-underlay_svg__filter0_d" x=".155" y="3.155" width="22.69" height="19.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset dy="3"></feOffset><feGaussianBlur stdDeviation="3.5"></feGaussianBlur><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend></filter></defs></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
